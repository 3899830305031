import React from "react"

const Loader = (props) => {

    const { loadingMessage, absoluteCenter } = props

    return (
        <div className={`loader-div ${absoluteCenter ? 'absoluteCenter' : ''}`}>
            <div className="text-center mb-3">
                <div className="loading mb-2"></div>
                {loadingMessage ? <span className="mr-0">{loadingMessage}</span> : null}
            </div>
        </div>
    )
}

export default Loader
